import React from "react";
import Layout from "../components/Layout";
import { graphql, useStaticQuery } from "gatsby";
import "../scss/main.scss";

export default function Resources() {
    const data = useStaticQuery(graphql`
    {
      allResourcesJson {
        edges {
          node {
            id
            url
            title
          }
        }
      }
    }
  `);

    return (
        <Layout pageTitle="Resources | Ikshana Learning">
            <section className="hero resources">
                <div className="resources__wrapper">
                    {data.allResourcesJson.edges.map(({ node }) => (
                        <div className="resources__container" key={node.id}>
                            <a href={node.url} target="_blank" rel="noreferrer">
                                <div className="resources__img-wrapper">
                                    <div className="resources__link">
                                        Ikshana Learning Brochure
                                    </div>
                                </div>
                            </a>
                            <h4>{node.title}</h4>
                        </div>
                    ))}
                </div>
            </section>
        </Layout>
    );
}
